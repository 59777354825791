.container-not-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 70vh;
  width: 100%;
  padding: 10px;

  .p404 {
    font-size: 70px;
    margin: 0;
  }

  p {
    font-size: 24px;
    color: rgb(207, 124, 0);
    font-style: italic;
  }
}
