.button {
  min-width: 270px;
  height: 50px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 10px #a3a3a3;

  &.button-validate {
    background-color: #138808;
    color: white;
    &:hover {
      background-color: #48c248;
    }
  }

  &.orange-button {
    background-color: #ff8902;
    color: white;
    &:hover {
      background-color: rgb(255, 179, 36);
    }
  }

  &.disabled {
    background-color: #dddddd;
    color: rgb(131, 131, 131);
    border: unset;
    pointer-events: none;
  }
}
