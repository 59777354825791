.warning-screen-size-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .warning-screen-text {
    text-align: center;
    font-size: 24px;
    color: rgb(207, 124, 0);
    font-style: italic;
  }
}
