@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Playfair+Display&display=swap');

.App {
  width: 100vw;
  // zoom: 100%;

  font-family: 'Playfair Display';
  color: #000;
  font-weight: 400;
  // min-width: 400px;
  margin: -8px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /* Chrome */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Opéra*/
  input::-o-inner-spin-button,
  input::-o-outer-spin-button {
    -o-appearance: none;
    margin: 0;
  }
}

.nm {
  margin: unset !important;
}

.margin130 {
  margin-top: 130px;
}

.margin85 {
  margin-top: 85px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
  font-size: 30px;
}

.title-page {
  text-align: center;
  font-size: 60px;
  margin: 32px 0;
  font-family: 'Alex Brush', sans-serif;
  font-weight: 400;
}

.description-text {
  text-align: center;
  margin: 0 0 32px 0;
  padding: 0 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  padding-bottom: 50px;

  label {
    margin-top: 40px;
  }

  input,
  select,
  textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    margin-top: 20px;

    &::placeholder {
      font-style: italic;
    }
  }

  input {
    height: 40px;
    padding: 5px 10px;
  }

  select {
    height: 50px;
    padding: 0 10px;
    cursor: pointer;

    option {
      font-weight: bold;
    }
  }

  textarea {
    height: 200px;
    padding: 10px 10px;
  }

  select:focus,
  input:focus,
  textarea:focus {
    outline: none !important;
    transition-duration: 0.3s;
    transition-delay: 0.1s;
    border: 1px solid rgb(255, 163, 26);
    box-shadow: 0 0 10px #ff8902;
  }

  .text-input-container,
  .select-input-container,
  .textarea-input-container {
    display: flex;
    flex-direction: column;
  }
}

.message-success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #3d9e3d;
}

@media screen and (max-width: 1250px) {
  form {
    width: 70%;
  }
}

@media screen and (max-width: 900px) {
  form {
    width: 90%;
  }
}
