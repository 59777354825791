.home {
  position: relative;
}

.block-white,
.block-grey {
  display: flex;
}

.block-grey {
  background-color: rgb(243, 243, 243);
}

.counter {
  height: 950px;
}

.bride-and-groom {
  height: 1100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}

.resume {
  height: 1100px;
}
