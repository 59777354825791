

.separator-line-one {
  margin: 32px auto 32px auto;
  border-bottom: 1px solid #138808;
  width: 300px;
}

.separator-line-two {
  margin: 32px auto 32px auto;
  border-bottom: 1px solid #ff9933;
  width: 300px;
}
