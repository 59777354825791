.counter-container {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;

  .date-counter {
    width: 300px;
    margin: unset;
    margin-top: 56px;
    font-size: 40px;
    font-family: 'Alex Brush', sans-serif;
  }

  .title-counter {
    font-size: 40px;
    margin-bottom: 16px;
  }

  .adresse {
    width: 300px;
    margin-bottom: 32px;
  }

  .countdown-section {
    background-color: #fff;
    margin: 8px auto;
    width: 112px;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 8px auto;
    }
  }

  .countdown-section p:first-child {
    color: rgb(255, 163, 26);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 24px;
  }
}

.img-flowers {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // max-width: 100%;
  // max-height: 100%;
  transform: translateY(10px);
  img {
    width: 320px;
    height: 113px;
  }
}
