.image-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95vh;
  overflow: hidden;
  position: relative;
}

.slider-image {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 3s;
  object-fit: 'cover';
}

.slider-image.active {
  opacity: 1;
}

.slider-text {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
}

.slider-text p {
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: unset;  
}

.top-text,
.bottom-text {
  font-size: 20px;
}

.center-text {
  font-family: 'Alex Brush', sans-serif;
  font-size: 40px;
  font-weight: 900;
}
