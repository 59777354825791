.container-convenience,
.container-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-convenience {
  width: 100vw;
  margin-bottom: 32px;
  max-height: 300px;
}

.img-contact {
  height: 300px;
  width: 300px;
  margin-bottom: 32px;
  border-radius: 150px;
}

.box {
  width: 300px;
  border-radius: 8px;
  padding: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0 auto;
}

.container-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 280px;

  & > * {
    margin-bottom: 16px;
  }
  & > a {
    text-decoration: none;
    color: #3d9e3d;
    margin-top: 0;
  }
}
.title-text {
  color: rgb(255, 163, 26);
  font-weight: 600;
}

.text {
  word-wrap: break-word;
  margin: 16px 0;
}

.phone-number {
  display: flex;
  align-items: center;
  justify-content: center;
}
