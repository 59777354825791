.timeline-photos {
  font-family: 'Playfair Display' !important;

  .vertical-timeline {
    padding: 0;
  }
  .vertical-timeline-element-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .vertical-timeline-element-subtitle {
    font-family: 'Playfair Display' !important;
    color: rgb(255, 163, 26);
    margin: 8px 0 !important;
    font-size: 24px;
  }

  p {
    color: #000 !important;
    font-size: 16px !important;
    margin: 8px 0 !important;
  }

  .vertical-timeline-element-date {
    font-size: 24px !important;
    margin: 8px 0 !important;
    padding: 0 !important;
  }
}
