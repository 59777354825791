.sidebar.scrollDown {
  height: 0;
  overflow: hidden;
  transition: height 1s;
}

.sidebar {
  width: 100vw;
  z-index: 3;
  position: fixed;
  top: 0%;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  display: flex;
  height: 80px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px;

  .image-title-site img {
    height: 80px;
  }

  .menu-bars {
    height: 40px;
    display: flex;
    color: #000;
    font-size: 40px;
    margin: auto 16px auto auto;
    opacity: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-menu {
    z-index: 9;
    display: flex;
    position: fixed;
    height: 100%;
    top: 0px;
    left: -100%;
    transition: 1000ms;
  }

  .nav-menu.active {
    width: 80%;
    left: 0;
    transition: 1000ms;
  }
  .image-title-site-active {
    margin: auto auto 16px auto;
  }

  .image-title-site-active img {
    height: 80px;
  }
  .close-button {
    color: #000;
    font-size: 30px;
    width: 100%;
    text-align: end;
    padding: 25px 25px 0 0;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .sidebar-open-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 12px #555;

    .sidebar-link-category {
      width: 100%;
      height: 50px;
      padding: 10px 40px;
      color: rgb(80, 80, 80);
      border-bottom: 0.5px solid transparent;
      cursor: pointer;

      &:hover {
        background-color: rgb(245, 245, 245);
        color: #000;
      }
    }
  }
}
