.rsvp {
  height: 91vh;
  min-height: 700px;
  background-color: rgb(243, 243, 243);
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('https://img3.wallspic.com/previews/9/0/6/1/3/131609/131609-bague_de_mariage-robe_de_mariee-anneau-bijouterie-mariage-x750.jpg');
  background-image: url('../../images/rsvp-img.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .rsvp-box {
    height: 60%;
    min-height: 500px;
    width: 70%;
    min-width: 320px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 24px;
  }
  
  .title-RSVP {
    font-family: 'Alex Brush', sans-serif;
    font-size: 32px;
    margin-bottom: 32px;
  }
}
