.container-resume {
  width: 100%;
  display: flex;
  align-items: center;
  background-image: url('../../images/home/white-flowers.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  .resume-text-container {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 85%;
    width: 80%;
    border-radius: 0;
    background-color: #fff;
  }

  p {
    text-align: center;
    color: #000;
    font-size: 18px;
    margin: 16px 0;
  }

  .title {
    line-height: 60px;
    font-size: 40px;
    font-family: 'Alex Brush', sans-serif;
    margin-bottom: 16px;
    text-align: center;
  }
}
